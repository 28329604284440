import React from "react";
import {Image} from "@aws-amplify/ui-react";
import EiaDialog from "./dialogs/EiaDialog";
import {COLOR_TEAL} from "../commons/enums/ColorEnums";

const defaultImageStyle = { border: '1px solid'};

const EiaImage = function (props) {

    const {tealBorder=false, ...restProps} = props;

    const [openImage, setOpenImage] = React.useState(false);

    const imageStyle = tealBorder === true ? {
        border: '2px solid ' + COLOR_TEAL,
    } : defaultImageStyle

    return(
        <div>
            <EiaDialog open={openImage} onClose={() => setOpenImage(false)} maxWidth={"xl"}>
                <Image alt={props.alt} src={props.src} style={defaultImageStyle}></Image>
            </EiaDialog>
            <Image onClick={() => setOpenImage(true)} {...restProps}
                style={imageStyle}
            />
        </div>
    )
}
export default EiaImage;