import React from "react";
import {Flex, Grid, SwitchField} from "@aws-amplify/ui-react";
import {d4RemoveNotifications} from "../commons/api/D4Api";

import EiaTable from "../components/tables/EiaTable";
import AlertCard from "../components/cards/AlertCard";
import {NOTIFICATION_HEADERS} from "../commons/TableHeaders";
import {findById} from "../commons/Utils/ArrayUtils";
import EiaCard from "../components/cards/EiaCard";
import EiaButton from "../components/EiaButton";
import Utils from "../commons/Utils/Utils";
import EiaButtonBar from "../components/EiaButtonBar";
import {sortByDate} from "../commons/Utils/SortUtils";
import EiaNotificationCard from "../components/EiaNotificationCard";
import LoadingDialog from "../components/dialogs/LoadingDialog";

const isSmallScreen = Utils.isSmallScreen();
const buttonsColumnEnd = isSmallScreen ? "-1" : "2";
const tableColumnStart = isSmallScreen ? "1" : "2";

const NotificationsPage = function (props) {

    const {notificationsState, reloadNotifications} = props;

    const [internalIsLoading, setInternalIsLoading] = React.useState(false);
    const [internalAlert, setInternalAlert] = React.useState(null)
    const alert = internalAlert ? internalAlert : notificationsState.alert;

    const [tableView, setTableView] = React.useState(false);
    let tableData = notificationsState.notifications
    tableData = tableData ? [...tableData] : [];
    tableData = tableData.sort(sortByDate)
    const isLoading = notificationsState.isLoading || internalIsLoading;
    const isLoadingOrNoData = isLoading || tableData.length === 0;

    const [selectedNotificationIds, setSelectedNotificationIds] = React.useState([])

    const selectedNotification = findById(tableData, selectedNotificationIds);

    React.useEffect(() => {
        reloadNotifications();
    }, []);

    const removeNotifications = function (all) {
        setInternalIsLoading(true)

        let request;

        if (all) {
            request = {
                all: true
            }
        } else {
            request = {
                all: false,
                idOfNotificationToRemove: selectedNotification.id
            }
        }

        d4RemoveNotifications(request, function (response) {
            if (response.removedNotifications && response.removedNotifications.length > 0) {
                setInternalAlert(["success", "Removed " + response.removedNotifications.length + " notifications"]);
                reloadNotifications();
            } else {
                setInternalAlert(["error", "Failed removing notification"]);
            }
            setInternalIsLoading(false)
        }, function (error) {
            setInternalAlert(["error", error.message]);
            setInternalIsLoading(false)
        })
    }

    const buttons = <EiaButtonBar>
        <SwitchField
            label="Table View"
            labelPosition="end"
            isChecked={tableView}
            onChange={(e) => {
                setTableView(e.target.checked);
            }}
        />
        <EiaButton isFullWidth={!isSmallScreen} onClick={reloadNotifications} disabled={isLoading} tooltip={"Reload all notifications"}>Reload</EiaButton>
        <EiaButton isFullWidth={!isSmallScreen} colorTheme={"error"} disabled={isLoadingOrNoData || selectedNotificationIds.length === 0 || !tableView}
                   onClick={() => removeNotifications(false)} tooltip={"Remove the selected notification"}>Remove</EiaButton>
        <EiaButton isFullWidth={!isSmallScreen} colorTheme={"error"} disabled={isLoadingOrNoData} onClick={() => removeNotifications(true)} tooltip={"Remove all"
            + " your"
            + " notifications"}>Remove All</EiaButton>
    </EiaButtonBar>

    return (
        <Grid templateColumns="2fr 9fr">
            <AlertCard alert={alert}/>
            <LoadingDialog open={isLoading} />
            <EiaCard
                columnStart="1"
                columnEnd={buttonsColumnEnd}
            >
                {buttons}
            </EiaCard>
            <EiaCard
                columnStart={tableColumnStart}
                columnEnd="-1"
            >
                {tableView ?
                    <EiaTable
                        isLoading={false}
                        data={tableData}
                        selectedRowId={selectedNotificationIds} setSelectedRowId={setSelectedNotificationIds}
                        tableHeader={NOTIFICATION_HEADERS}
                    />
                    :
                    <Flex direction={"column"}>
                        {tableData.map((element, index) => {
                            return <EiaNotificationCard key={index} notification={element}/>
                        })}
                    </Flex>
                }

            </EiaCard>
        </Grid>
    )
}
export default NotificationsPage;