import React from "react";
import {d4ChangeAuctionStatus, d4GetMyBids, d4UpdateBid} from "../commons/api/D4Api";
import EiaButton from "../components/EiaButton";
import {findByKeyValue} from "../commons/Utils/ArrayUtils";
import TradeDialog from "../components/dialogs/TradeDialog";
import {MY_SALES_TABLE_HEADERS} from "../commons/TableHeaders";
import ShowDetailsButton from "../components/ShowDetailsButton";
import SelectBidderDialog from "../components/dialogs/SelectBidderDialog";
import {bidsForMyAuction} from "../commons/Converter";
import EditAuctionDialog from "../components/dialogs/EditAuctionDialog";
import {useCookies} from "react-cookie";
import EiaAuctionsWithButtons from "../components/EiaAuctionsWithButtons";
import Utils from "../commons/Utils/Utils";

const tableInitialState = {
    sorting: {
        sortModel: [{field: 'creationDate', sort: 'desc'}],
    },
}

const MySalesPage = function (props) {
    const {platform, region, mySalesState} = props;

    const [cookies, setCookies, removeCookies] = useCookies(['mode', 'realm']);
    const mode = cookies.mode;
    const realm = cookies.realm;

    const [isLoading, setIsLoading]  = React.useState(false);
    const [alert, setAlert] = React.useState(null)

    const tableData = mySalesState.auctions;

    //selectedAuctionId is an array because we get it from a table and there could be more selected elements ( but we only use 1)
    const [selectedAuctionId, setSelectedAuctionId] = React.useState([])

    const [openTradeDialog, setOpenTradeDialog] = React.useState(false);
    const [openEditDialog, setOpenEditDialog] = React.useState(false);
    const [openSelectBidderDialog, setOpenSelectBidderDialog] = React.useState(false);
    const [openAuctionSpecificDialog, setOpenAuctionSpecificDialog] = React.useState(false);

    const reloadMySales = function(keepAlert) {
        if(keepAlert) {
            mySalesState.reload(setIsLoading, Utils.doNothing, mode, realm);
        } else {
            mySalesState.reload(setIsLoading, setAlert, mode, realm);
        }

    }
    
    React.useEffect(() => {
        setOpenTradeDialog(false);
        reloadMySales();
    }, []);

    const selectedAuction = selectedAuctionId.length === 0 ? null : findByKeyValue(tableData, "id", selectedAuctionId);

    const abort = function () {
        setIsLoading(true)

        const request = {
            platform: platform,
            region: region,
            mode: mode,
            realm: realm,
            id: selectedAuctionId[0],
            status: "ABORTED"
        }

        d4ChangeAuctionStatus(request, function (response) {
            setAlert(["success", "Successfully aborted auction"]);
            reloadMySales(true);
            setIsLoading(false);
        }, function (error) {
            setAlert(["error", error.message]);
            reloadMySales(true);
            setIsLoading(false);
        })
    }

    const reactivate = function () {
        if (!selectedAuction) {
            setAlert(["error", ("Cannot find selected auction with id " + selectedAuctionId)])
            return;
        }

        setIsLoading(true)

        const request = {
            platform: platform,
            region: region,
            mode: mode,
            realm: realm,
            id: selectedAuctionId[0],
            status: "ACTIVE"
        }

        d4ChangeAuctionStatus(request, function (response) {
            setAlert(["success", ("Successfully reactivated auction with item " + response.auction.item.name)]);
            reloadMySales(true);
            setIsLoading(false);
        }, function (error) {
            setAlert(["error", error.message]);
            reloadMySales(true);
            setIsLoading(false);
        })
    }

    const edit = function () {
        if (selectedAuction.bidders.length > 0) {
            setAlert(["error", "Cannot edit '" + selectedAuction.itemName + "' since there is at least one bidder"]);
            return;
        }

        setOpenEditDialog(true)
    }

    const closeEditDialog = function (refresh) {
        setOpenEditDialog(false);
        if (refresh) {
            reloadMySales();
        }
    }

    const [bids, setBids] = React.useState([]);
    const [auctionForBids, setAuctionForBids] = React.useState({});

    const reloadBidsInSelectBidderDialog = function (auctionId) {
        if(auctionId == null) {
            auctionId = selectedAuctionId[0];
        }

        if(auctionId == null) {
            setAlert(["error", "Cannot reload bids because no auction is selected"]);
            setIsLoading(false);
            return;
        }

        setIsLoading(true);
        setAuctionForBids(selectedAuction);

        const request = {
            platform: platform,
            region: region,
            mode: mode,
            realm: realm,
            auctionId: auctionId
        }

        //we get bids for my auction here - i need a new api method with better name
        d4GetMyBids(request, function (response) {
            if (response.bids) {
                let tempBids = bidsForMyAuction(response.bids, setAlert)
                setBids(tempBids)
            } else {
                setBids([])
            }
            setIsLoading(false)
        }, function (error) {
            setAlert(["error", error.message]);
            setIsLoading(false)
        })
    }

    const accept = function (bidder, auctionId) {
        setIsLoading(true)

        const request = {
            platform: platform,
            region: region,
            mode: mode,
            realm: realm,
            id: auctionId,
            status: "TRADE",
            buyer: bidder
        }

        d4ChangeAuctionStatus(request, function (response) {
            reloadMySales();
            // reloadBidsInSelectBidderDialog(auctionId);
        }, function (error) {
            setAlert(["error", error.message]);
            reloadMySales(true);
            // reloadBidsInSelectBidderDialog(auctionId);
        })
    }

    const reject = function (bidder, auctionId) {
        setIsLoading(true)

        const request = {
            platform: platform,
            region: region,
            mode: mode,
            realm: realm,
            auctionId: auctionId,
            status: "WITHDRAWN",
            bidder: bidder
        }

        d4UpdateBid(request, function (response) {
            reloadBidsInSelectBidderDialog(auctionId);
        }, function (error) {
            setAlert(["error", error.message]);
            reloadBidsInSelectBidderDialog(auctionId);
        })
    }

    const auctionSpecificButtons = [
        <ShowDetailsButton key={"detailsButton"} isFullWidth disabled={isLoading || selectedAuctionId.length === 0} title={"Details"} details={selectedAuction} additionalOnClose={() => setOpenAuctionSpecificDialog(false)}/>,
        <EiaButton key={"editButton"} isFullWidth onClick={() => {
            setOpenAuctionSpecificDialog(false)
            edit()
        }} disabled={isLoading || selectedAuctionId.length === 0} tooltip={"Edit the item, currency or price of your sale"}>Edit ...</EiaButton>,
        <EiaButton key={"abortButton"} isFullWidth colorTheme={"error"} onClick={() => {
            setOpenAuctionSpecificDialog(false)
            abort()
        }} disabled={isLoading || selectedAuctionId.length === 0} tooltip={"Abort your sale"}>Abort</EiaButton>,
        <EiaButton key={"bidsButton"} isFullWidth onClick={() => {
            setOpenAuctionSpecificDialog(false);
            setOpenSelectBidderDialog(true);
            reloadBidsInSelectBidderDialog();
        }
        }
                   colorTheme={selectedAuction && selectedAuction.status == "ACTIVE" ? "success" : null} disabled={isLoading || !selectedAuction || selectedAuction.bidders.length === 0}
                   tooltip={"Accept or reject bidders for your sales"}>Bids ...</EiaButton>,
        <EiaButton key={"reactivateButton"} isFullWidth onClick={() => {
            setOpenAuctionSpecificDialog(false)
            reactivate()
        }}
                   disabled={isLoading || !selectedAuction || selectedAuction.status !== "EXPIRED"} tooltip={"Reactivate sales with status EXPIRED"}>Reactivate</EiaButton>,
        <EiaButton key={"tradeButton"} isFullWidth colorTheme={"success"} onClick={() => {
            setOpenAuctionSpecificDialog(false)
            setOpenTradeDialog(true)
        }}
                   disabled={isLoading || !selectedAuction || selectedAuction.status !== "TRADE"} tooltip={"Trade sales with status TRADE"}>Trade ...</EiaButton>
    ]

    const generalButtons = [
        <EiaButton key={"reloadButton"} isFullWidth onClick={reloadMySales} disabled={isLoading} tooltip={"Reloads the item data"}>Reload</EiaButton>
    ]

    const closeSelectBiddersDialog = function(reloadAuctions) {
        setOpenSelectBidderDialog(false);
        if(reloadAuctions) {
            reloadMySales();
        }
    }

    return (
        <div>
            <SelectBidderDialog open={openSelectBidderDialog}
                                onClose={closeSelectBiddersDialog}
                                accept={accept}
                                reject={reject}
                                bids={bids}
                                auction={auctionForBids}
            />
            <TradeDialog open={openTradeDialog}
                         isSeller={true}
                         onCancel={() => setOpenTradeDialog(false)}
                         auction={selectedAuction ? selectedAuction : null}
                         salesState={mySalesState}
            />
            <EditAuctionDialog open={openEditDialog} onClose={closeEditDialog} auction={selectedAuction}/>
            <EiaAuctionsWithButtons
                alert={alert}
                isLoading={isLoading}
                tableData={tableData}
                tableHeaders={MY_SALES_TABLE_HEADERS}
                tableInitialState={tableInitialState}
                generalButtons={generalButtons}
                auctionSpecificButtons={auctionSpecificButtons}
                selectedAuctionId={selectedAuctionId}
                selectedAuction={selectedAuction}
                setSelectedAuctionId={setSelectedAuctionId}
                openAuctionSpecificDialog={openAuctionSpecificDialog}
                setOpenAuctionSpecificDialog={setOpenAuctionSpecificDialog}
                isViewFromSeller={true}
                />
        </div>
    )
}
export default MySalesPage;