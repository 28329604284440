import {Flex, Heading} from "@aws-amplify/ui-react";
import EiaTitle from "../../components/EiaTitle";
import EiaFixedTable from "../../components/tables/EiaFixedTable";
import React from "react";
import {distilledFear, exquisiteBlood, livingSteel, malignantHeart, mucusSlickEgg, pincushionedDoll, sandScorchedShackles, shardOfAgony} from "../../commons/wowhead/D4ItemLinks";
import {difficultyLink, runesLink, summoningMaterialsLink, uniquesLink} from "../../commons/wowhead/D4Uniques";
import Utils from "../../commons/Utils/Utils";

const isSmallScreen = Utils.isSmallScreen();
const tableEntryFlexDirection = isSmallScreen ? "column" : "row"

const drops = [uniquesLink, ", ",summoningMaterialsLink,", ",runesLink, ", Gem Fragments, Crafting Materials, Gold"]

const bossTableHeader = ["Boss", "Summoning Materials", "Drop Table"];
const bossData = [
    ["Varshan", <Flex direction={tableEntryFlexDirection}>{malignantHeart(4)}</Flex>, drops],
    ["Grigoire", livingSteel(12), drops],
    ["Beast in Ice", distilledFear(12), drops],
    ["Zir",exquisiteBlood(12), drops],
    ["Duriel", <Flex direction={tableEntryFlexDirection}>{mucusSlickEgg(2)}{shardOfAgony(2)}</Flex>, drops],
    ["Andariel", <Flex direction={tableEntryFlexDirection}>{pincushionedDoll(2)}{sandScorchedShackles(2)}</Flex>, drops],
];

const dropQuantitiesTableHeader = [difficultyLink, uniquesLink, summoningMaterialsLink, runesLink, "Gem Fragments"];
const dropQuantitiesData = [
    ["Torment 1", "1-2", "1-2", "0-1", "1200"],
    ["Torment 2", "2-3", "1-3", "0-2", "1400"],
    ["Torment 3", "3-4", "1-3", "0-3", "1600"],
    ["Torment 4", "5", "2-3", "0-3", "2000"],
];

const BossSummoning = function() {

    return         <Flex direction={"column"}>
        <EiaTitle title={"Boss Summoning (Season 6)"} lastUpdated={"2024/10/08"}/>
        <Heading level={6}>Bosses</Heading>
        <EiaFixedTable data={bossData} tableHeader={bossTableHeader}/>
        <Heading level={6}>Boss Drop Quantities</Heading>
        <EiaFixedTable data={dropQuantitiesData} tableHeader={dropQuantitiesTableHeader}/>
    </Flex>
}

export default BossSummoning;