import {Flex, Heading} from "@aws-amplify/ui-react";
import EiaTitle from "../../components/EiaTitle";
import EiaFixedTable from "../../components/tables/EiaFixedTable";
import React from "react";
import {
    distilledFear,
    exquisiteBlood,
    livingSteel,
    malignantHeart,
    mucusSlickEgg,
    pincushionedDoll,
    sandScorchedShackles,
    shardOfAgony,
    stygianStone
} from "../../commons/wowhead/D4ItemLinks";
import {beastInIceUniques, bossUniqueDrops, grigoireUniques, mythicsLink, varshanUniques, zirUniques} from "../../commons/wowhead/D4Uniques";
import Utils from "../../commons/Utils/Utils";

const isSmallScreen = Utils.isSmallScreen();
const tableEntryFlexDirection = isSmallScreen ? "column" : "row"

const bossTableHeader = ["Boss", "Level", "World Tier", "Summoning Materials", "Drops"];
const bossData = [
    ["Varshan", "55", "T3", <Flex direction={tableEntryFlexDirection}>{malignantHeart(2)}</Flex>, varshanUniques],
    ["Varshan", "75", "T4", <Flex direction={tableEntryFlexDirection}>{malignantHeart(5)}</Flex>,
        <Flex direction={tableEntryFlexDirection}>{varshanUniques}{mucusSlickEgg()}</Flex>],
    ["Grigoire", "60", "T3", livingSteel(2), grigoireUniques],
    ["Grigoire", "75", "T4", livingSteel(5), <Flex direction={tableEntryFlexDirection}>{grigoireUniques}{shardOfAgony()}</Flex>],
    ["Beast in Ice", "85", "T4", distilledFear(9), <Flex direction={tableEntryFlexDirection}>{beastInIceUniques}{pincushionedDoll()}</Flex>],
    ["Zir", "80", "T4", exquisiteBlood(9), <Flex direction={tableEntryFlexDirection}>{zirUniques}{sandScorchedShackles()}</Flex>],
    ["Uber Duriel", "100", "T4", <Flex direction={tableEntryFlexDirection}>{mucusSlickEgg(2)}{shardOfAgony(2)}</Flex>,
        <Flex direction={tableEntryFlexDirection}>{bossUniqueDrops}{mythicsLink}</Flex>],
    ["Uber Andariel", "100", "T4", <Flex direction={tableEntryFlexDirection}>{pincushionedDoll(2)}{sandScorchedShackles(2)}</Flex>,
        <Flex direction={tableEntryFlexDirection}>{bossUniqueDrops}{mythicsLink}</Flex>],
    // ["Uber Malphas", "100", "T4", igneousCore(7), <Flex>{bossUniqueDrops}{genesis()}{evernight()}</Flex>],
    ["Any torment boss", "200", "T4", <Flex direction={tableEntryFlexDirection}>{stygianStone()}{" + 3 times summong materials from the non-torment boss"}</Flex>,
        <Flex direction={tableEntryFlexDirection}>{bossUniqueDrops}{mythicsLink}</Flex>]
];

const defaultMaterialDropLocation = "Whisper quests, world boss, elites, treasure goblins, helltide chests";

const summoningMaterialTableHeader = ["Summoning Material", "Drop Location", "For Boss Summoning"];
const summoningMaterialData = [
    // [igneousCore(), "Season 3 open world arcane tremors", "Uber Malphas"],
    [livingSteel(), <Flex>{defaultMaterialDropLocation}{" (guaranteed)"}</Flex>, "Grigoire"],
    [malignantHeart(), defaultMaterialDropLocation, "Varshan"],
    [exquisiteBlood(), defaultMaterialDropLocation, "Zir"],
    [distilledFear(), <Flex>{defaultMaterialDropLocation} {"Nightmare Dungeons"}</Flex>, "Beast in Ice"],
    [mucusSlickEgg(), <Flex>{"Varshan T4, "} {defaultMaterialDropLocation}</Flex>, "Uber Duriel"],
    [shardOfAgony(), <Flex>{"Grigoire T4, "} {defaultMaterialDropLocation}</Flex>, "Uber Duriel"],
    [pincushionedDoll(), <Flex>{"Beast in Ice T4, "} {defaultMaterialDropLocation}</Flex>, "Uber Andariel"],
    [sandScorchedShackles(), <Flex>{"Zir T4, "} {defaultMaterialDropLocation}</Flex>, "Uber Andariel"],
    [stygianStone(), "The pit (drop chance 5% tier 1, 10% tier 40, 18% tier 100, 30% tier 200)", "Any torment boss"],
];

const BossSummoningS5 = function() {

    return         <Flex direction={"column"}>
        <EiaTitle title={"Boss Summoning (Season 5)"} lastUpdated={"2024/08/07"}/>
        <Heading level={6}>Bosses</Heading>
        <EiaFixedTable data={bossData} tableHeader={bossTableHeader}/>
        <Heading level={6}>Summoning Materials</Heading>
        <EiaFixedTable data={summoningMaterialData} tableHeader={summoningMaterialTableHeader}/>
    </Flex>
}

export default BossSummoningS5;