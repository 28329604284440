import React from "react";
import EiaButton from "../EiaButton";
import {Divider, Flex, Heading} from "@aws-amplify/ui-react";
import EiaDialog from "./EiaDialog";
import {useCookies} from "react-cookie";
import {d4CreateBid} from "../../commons/api/D4Api";
import EiaCard from "../cards/EiaCard";
import EiaTextField from "../EiaTextField";
import {testInteger} from "../../commons/Utils/RegExUtils";
import CurrencySelects from "../CurrencySelects";
import {contains} from "../../commons/Utils/ArrayUtils";
import {getCurrencyTypeByCurrency, ITEM_SUB_TYPES_WITH_AMOUNT} from "../../commons/enums/D4Enums";
import LocalStorage from "../../commons/LocalStorage";
import AlertCard from "../cards/AlertCard";
import LoadingDialog from "./LoadingDialog";
import D4ItemCardWithPrice from "../d4/D4ItemCardWithPrice";

function BidDialog(props) {
    const {
        platform,
        region,
        open,
        setOpen,
        setAlert,
        selectedAuction,
        selectedAuctionId,
        searchAuctions
    } = props;

    const [cookies, setCookies, removeCookies] = useCookies(['mode', 'realm']);
    const [isLoading, setIsLoading] = React.useState(false)
    const mode = cookies.mode;
    const realm = cookies.realm;

    const isItemWithAmount = selectedAuction ? contains(ITEM_SUB_TYPES_WITH_AMOUNT, selectedAuction.itemSubType) : false;
    const selectedAuctionAmount = isItemWithAmount && selectedAuction.amount > 1 ? selectedAuction.amount : 1;

    const getCurrencyFromSelectedAuction=function () {
        const temp = {};
        temp.name = selectedAuction.currency;
        temp.type = getCurrencyTypeByCurrency(temp.name);
        temp.price = selectedAuction.price;
        return temp;
    }

    const getItemAmount=function () {
        return isItemWithAmount ? selectedAuction.amount : 1;
    }

    const initialBidderCurrency = getCurrencyFromSelectedAuction();
    const initialBidderAmount = getItemAmount();

    const [bidderCurrency, setBidderCurrency] = React.useState(initialBidderCurrency)
    const [bidderAmount, setBidderAmount] = React.useState(initialBidderAmount);

    const [internalAlert, setInternalAlert] = React.useState(null)

    React.useEffect(() => {
        if(open) {
            //we get here once everytime the dialog opens
            const temp = getCurrencyFromSelectedAuction();
            setBidderCurrency(temp);

            const itemAmount = getItemAmount();
            setBidderAmount(itemAmount);
        }
    }, [open])

    const bid = function() {
        setIsLoading(true);
        if(selectedAuction.seller === LocalStorage.getUser()) {
            setInternalAlert(["error", "You cannot bid on your own items!"]);
            setIsLoading(false);
            return;
        }

        if(!selectedAuction) {
            setInternalAlert(["error", ("Cannot find selected auction with id " + selectedAuctionId)])
            setIsLoading(false);
            return;
        }

        const request = {
            platform: platform,
            region: region,
            mode: mode,
            realm: realm,

            auctionId: selectedAuctionId,
            seller: selectedAuction.seller,
            amount: isItemWithAmount ? bidderAmount : 1,
            price: bidderCurrency.price,
            currency: bidderCurrency.name
        }

        d4CreateBid(request, function (response) {
            const itemDescription = response.auction.item.title ? response.auction.item.title : response.auction.item.name;
            setAlert(["success", ("Successfully created bid on item '" + itemDescription + "' for " + request.price + " " + request.currency) + ". You get a notification when"
            + " the seller reacts to your bid."]);
            close();
            searchAuctions(true);
        }, function (error) {
            setIsLoading(false);
            setInternalAlert(["error", error.message]);
        })
    }

    const close=function() {
        setInternalAlert(false);
        setIsLoading(false);
        setOpen(false);
    }

    return (
        <EiaDialog open={open} title={"Bid for " + selectedAuction.itemName} onClose={close}>
            <LoadingDialog open={isLoading} />
            <Flex direction={"column"}>
                <D4ItemCardWithPrice auctionMergedWithBid={selectedAuction}/>
                <Divider/>
                <EiaCard>
                    <Flex direction={"column"}>
                        <Heading level={6}>Choose your bid</Heading>
                        { isItemWithAmount ? <EiaTextField validator={testInteger} onChange={(e) => setBidderAmount(e.currentTarget.value)}
                                                           label="Amount" value={bidderAmount}/> : null}
                        <CurrencySelects currency={bidderCurrency} setCurrency={setBidderCurrency} addAnyCurrencyType={false} addPrice={true} setAlert={setAlert}/>
                        <AlertCard alert={internalAlert} />
                        <EiaButton colorTheme={"success"} isFullWidth onClick={bid} disabled={isLoading || selectedAuctionId.length === 0}>Bid</EiaButton>
                    </Flex>
                </EiaCard>
            </Flex>
        </EiaDialog>
    );
}

export default BidDialog;