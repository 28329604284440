import React from "react";
import {contains, findIndexIgnoreCase, remove} from "../../commons/Utils/ArrayUtils";
import EiaTextField from "../EiaTextField";
import {testInteger} from "../../commons/Utils/RegExUtils";
import EiaSelect from "../EiaSelect";
import {DEFAULT_ITEM_RARITY, ITEM_NAMES, ITEM_RARITIES, ITEM_SUB_TYPES, ITEM_SUB_TYPES_WITH_AMOUNT, ITEM_TYPES, ITEM_TYPES_WITH_RARITY,} from "../../commons/enums/D4Enums";
import {Button, Icon} from "@aws-amplify/ui-react";
import {RiDeleteBin6Line} from "react-icons/ri";

const D4ItemTypesSelect = function (props) {
    const {showAmount, baseItem, setBaseItem, removeAny, hideRarity, hideName, titleEditable, hideTitle=false} = props;

    const isBaseItem = Object.keys(baseItem).length > 0;

    const itemTypes = removeAny ? remove(ITEM_TYPES, ["Any"]) : ITEM_TYPES;
    const itemType = isBaseItem ? baseItem.itemType : itemTypes[0];
    const itemSubTypes = ITEM_SUB_TYPES[itemType]
    const itemSubType = isBaseItem ? baseItem.itemSubType : itemSubTypes[0];
    const itemNames = removeAny ? remove(ITEM_NAMES[itemSubType], ["Any"]) : ITEM_NAMES[itemSubType];
    const itemName = isBaseItem ? baseItem.name : itemNames[0];
    const itemTitle = isBaseItem ? baseItem.title : "";
    const itemRarities = removeAny ? remove(ITEM_RARITIES, ["Any"]) : ITEM_RARITIES;

    const isItemWithRarity = contains(ITEM_TYPES_WITH_RARITY, itemType);
    const itemRarityIndex = isItemWithRarity && isBaseItem && baseItem.rarity ? findIndexIgnoreCase(itemRarities, baseItem.rarity) : 0;
    const itemTypeIndex = findIndexIgnoreCase(itemTypes, itemType);
    const itemSubTypeIndex = findIndexIgnoreCase(itemSubTypes, itemSubType);
    const itemNameIndex = findIndexIgnoreCase(itemNames, itemName);
    const amount = isBaseItem ? baseItem.amount : 1;

    React.useEffect(() => {
        const temp = {};
        temp.itemType = itemType;
        temp.itemSubType = itemSubType;
        temp.name = itemName;
        temp.amount = amount;
        temp.title = itemTitle;
        if(isItemWithRarity) {
            temp.rarity = itemRarities[itemRarityIndex];
        }
        setBaseItem(temp);
    }, []);

    const updateItemRarity = function (value) {
        const temp = Object.assign([], baseItem);
        temp.rarity = itemRarities[value]
        setBaseItem(temp);
    }

    const updateItemTypeIndex = function (value) {
        const temp = Object.assign([], baseItem);

        temp.itemType = itemTypes[value];
        const itemSubTypes = ITEM_SUB_TYPES[temp.itemType];
        temp.itemSubType = itemSubTypes[0];
        const itemNames = removeAny ? remove(ITEM_NAMES[temp.itemSubType], ["Any"]) : ITEM_NAMES[temp.itemSubType];
        temp.name = itemNames[0];

        const isItemWithRarity = contains(ITEM_TYPES_WITH_RARITY, temp.itemType);
        if(isItemWithRarity) {
            if(temp.rarity === null) {
                temp.rarity = DEFAULT_ITEM_RARITY;
            }
        } else {
            temp.rarity = null;
        }

        if(!contains(ITEM_SUB_TYPES_WITH_AMOUNT, temp.itemSubType)) {
            temp.amount = 0;
        }

        setBaseItem(temp);
    }

    const updateItemSubTypeIndex = function (value) {
        const temp = Object.assign([], baseItem);

        temp.itemSubType = itemSubTypes[value];
        const itemNames = removeAny ? remove(ITEM_NAMES[temp.itemSubType], ["Any"]) : ITEM_NAMES[temp.itemSubType];
        temp.name = itemNames[0];

        if(!contains(ITEM_SUB_TYPES_WITH_AMOUNT, temp.itemSubType)) {
            temp.amount = 0;
        }

        setBaseItem(temp);
    }

    const updateItemNameIndex = function (value) {
        const temp = Object.assign([], baseItem);
        temp.name = itemNames[value];

        setBaseItem(temp);
    }

    const updateAmount = function (value) {
        const temp = Object.assign([], baseItem);
        temp.amount = value;

        setBaseItem(temp);
    }

    const updateTitle = function (value) {
        const temp = Object.assign([], baseItem);
        temp.title = value;

        setBaseItem(temp);
    }

    return (
        <div>
            <EiaSelect title={"Item Type"} values={itemTypes} value={itemTypeIndex}
                       onChange={(e) => updateItemTypeIndex(e.target.value)}/>
            <EiaSelect title={"Item Sub Type"} values={itemSubTypes} value={itemSubTypeIndex}
                       onChange={(e) => updateItemSubTypeIndex(e.target.value)}/>
            {
                hideName ? null :             <EiaSelect title={"Item"} values={itemNames} value={itemNameIndex}
                                                         onChange={(e) => updateItemNameIndex(e.target.value)}/>
            }
            {
                showAmount && contains(ITEM_SUB_TYPES_WITH_AMOUNT, itemSubType) ?
                    <EiaTextField validator={testInteger} onChange={(e) => updateAmount(e.currentTarget.value)}
                                  label="Amount" value={amount}/>
                    : null
            }
            {
                !hideRarity && isItemWithRarity ? <EiaSelect title={"Item Rarity"} values={itemRarities} value={itemRarityIndex} onChange={(e) => updateItemRarity(e.target.value)}/> : null
            }
            {
                hideTitle ? null :             <EiaTextField disabled={!titleEditable} isReadOnly={!titleEditable} onChange={(e) => updateTitle(e.currentTarget.value)}
                                                             label="Title" value={itemTitle} outerEndComponent={
                    titleEditable ? null : <Button  size={"large"} variation={"link"} onClick={() => updateTitle("")}>
                        <Icon ariaLabel="Javascript" as={RiDeleteBin6Line}/>
                    </Button>
                }/>
            }

        </div>


    )
}
export default D4ItemTypesSelect;