import React from "react";
import {Divider, Flex, Label} from "@aws-amplify/ui-react";
import {contains, findAllByKeyValue} from "../../commons/Utils/ArrayUtils";
import {
    getRuneSetDescription,
    getSummoningDescription,
    isLegendaryRune,
    isMagicRune,
    isRareRune,
    isRune,
    isRuneSet,
    isSummoningMaterial,
    ITEM_RARITIES,
    ITEM_SUB_TYPES_WITHOUT_STATS,
    ITEM_TYPES_WITH_RARITY
} from "../../commons/enums/D4Enums";
import backgroundImage from "../../resources/itemframe_plain.png";
import "./D4ItemCard.css";
import {
    COLOR_BLUE,
    COLOR_GRAY,
    COLOR_GREAT_AFFIX,
    COLOR_LEGENDARY,
    COLOR_MAGIC,
    COLOR_MYTHIC,
    COLOR_OCHRE,
    COLOR_ORANGE,
    COLOR_RARE,
    COLOR_RUNE,
    COLOR_SUMMONING,
    COLOR_UNIQUE,
    COLOR_WHITE,
    COLOR_YELLOW
} from "../../commons/enums/ColorEnums";

const withBackgroundImage = {
    backgroundImage: `url(${backgroundImage})`
}

const legendaryStyle = {color: COLOR_LEGENDARY};
const magicStyle = {color: COLOR_MAGIC};
const grayStyle = {color: COLOR_GRAY};
const whiteStyle = {color: COLOR_WHITE};
const greatAffixStyle = {color: COLOR_GREAT_AFFIX};
const uniqueStyle = {color: COLOR_UNIQUE};

const summoningMaterialTypeStyle = {
    color: COLOR_SUMMONING,
    fontSize: "0.75em"
}

const summoningNameStyle = {
    color: COLOR_SUMMONING,
    fontSize: "1.5em",
    textTransform: "uppercase"
}

const runeSetItemNameStyle = {
    color: COLOR_MYTHIC,
    fontSize: "1.5em",
    textTransform: "uppercase"
}

const magicRuneNameStyle = {
    color: COLOR_MAGIC,
    fontSize: "1.5em",
    textTransform: "uppercase"
}

const rareRuneNameStyle = {
    color: COLOR_RARE,
    fontSize: "1.5em",
    textTransform: "uppercase"
}

const legendaryRuneNameStyle = {
    color: COLOR_LEGENDARY,
    fontSize: "1.5em",
    textTransform: "uppercase"
}

const runeItemNameStyle = {
    color: COLOR_RUNE,
    fontSize: "1.5em",
    textTransform: "uppercase"
}

const normalTextStyle = {
    color: COLOR_GRAY
}

const getTitleStyle = function(color) {
    return {
        fontSize: "1.5em",
        textTransform: "uppercase",
        color: color
    }
}

const getTypeStyle = function(color) {
    return {
        fontSize: "0.75em",
        color: color
    }
}

const getTextStyle = function (rarity, isTitle) {
    if (!rarity) {
        return isTitle ? getTitleStyle(COLOR_WHITE) : getTypeStyle(COLOR_WHITE);
    }

    let color = COLOR_WHITE;

    if (rarity === ITEM_RARITIES[1]) {
        color = COLOR_OCHRE;
    } else if (rarity === ITEM_RARITIES[2]) {
        color = COLOR_ORANGE;
    } else if (rarity === ITEM_RARITIES[3]) {
        color = COLOR_YELLOW;
    } else if (rarity === ITEM_RARITIES[4]) {
        color = COLOR_BLUE;
    }

    return isTitle ? getTitleStyle(color) : getTypeStyle(color);
}

const D4ItemCard = function (props) {
    const {item, maxHeight, amount} = props;

    React.useEffect(() => {
    }, [item]);

    const isArmor = item.armor && item.armor > 0;
    const isDps = item.dps && item.dps > 0;

    const itemType = item.type ? item.type : item.itemType;
    const itemSubType = item.subType ? item.subType : item.itemSubType;

    const isWithoutStats = contains(ITEM_SUB_TYPES_WITHOUT_STATS, itemSubType);
    const isItemWithRarity = contains(ITEM_TYPES_WITH_RARITY, itemType);

    const amountText = amount && amount > 1 ? " (" + amount + ")" : "";

    const titleStyle = getTextStyle(isItemWithRarity ? item.rarity : null, true);
    const typeStyle = getTextStyle(isItemWithRarity ? item.rarity : null, false);
    const isItemSummoningMaterial = isSummoningMaterial(item);
    let isCustomItem = false;
    let customItemDescription = [];
    let customItemNameStyle = "";
    if(isItemSummoningMaterial) {
        isCustomItem = true;
        customItemDescription = getSummoningDescription(item.name);
        customItemNameStyle = summoningNameStyle;
    }

    if(isRuneSet(item)) {
        isCustomItem = true;
        customItemDescription = getRuneSetDescription(item.name);
        customItemNameStyle = runeSetItemNameStyle;
    }

    if(isRune(item)) {
        isCustomItem = true;
        customItemNameStyle = runeItemNameStyle;
        if(isMagicRune(item)) {
            customItemNameStyle = magicRuneNameStyle;
        }

        if(isRareRune(item)) {
            customItemNameStyle = rareRuneNameStyle;
        }

        if(isLegendaryRune(item)) {
            customItemNameStyle = legendaryRuneNameStyle;
        }
    }

    const itemName = isItemWithRarity ? (item.rarity ? item.rarity + " " + item.name : "Normal " + item.name) : item.name;

    const mapStat=function(stat, index, isAspect, isUniqueAffix) {
        //TODO: support multiple values X_0, X_1, X_2
        const rangePart1 = stat.floatMinValues ? stat.floatMinValues[0] : "";
        const statMaxValue = stat.floatMaxValues ? stat.floatMaxValues[0] : "";
        const rangePart2 = rangePart1 && statMaxValue ? " - " + statMaxValue : statMaxValue;
        const statRange = rangePart1 || statMaxValue ? " [" + rangePart1 + rangePart2 + "]" : "";
        const statValue = stat.floatValues && stat.floatValues[0] ? stat.floatValues[0] : 0;
        if(isAspect === true || isUniqueAffix === true) {
            const textStyle = isAspect === true ? legendaryStyle : uniqueStyle;

            let firstLabel;
            let parts;
            if(stat.name.includes("X_0")) {
                parts = stat.name.split("X_0");
                firstLabel = isAspect === true ?
                    <Label key={index + " label 0"} style={textStyle}>&#9733; {parts[0]}</Label> :
                    <Label key={index + " label 0"} style={textStyle}>&#10037; {parts[0]}</Label>;
            } else {
                firstLabel = isAspect === true ?
                    <Label key={index + " label 0"} style={textStyle}>&#9733; {stat.name} </Label> :
                    <Label key={index + " label 0"} style={textStyle}>&#10037; {stat.name} </Label>;
            }

            if(stat.name.includes("X_0")) {
                return <div key={index}>
                    {firstLabel}
                    <Label key={index + " label 1"} style={magicStyle}>{statValue}</Label>
                    <Label key={index + " label 2"} style={grayStyle}>{statRange}</Label>
                    <Label key={index + " label 3"} style={textStyle}>{parts[1]}</Label>
                </div>
            } else {
                return firstLabel;
            }
        } else {
            if(stat.greaterAffix) {
                return <div key={index}>
                    <Label key={index + " label 0"} style={greatAffixStyle}>&#10039;</Label>
                    <Label key={index + " label 1"} style={whiteStyle}>{(stat.name.replace("X_0", statValue)) + statRange}</Label>
                </div>
            } else {
                return <div style={normalTextStyle} key={index + " label 0"}>&#x25C6; {(stat.name.replace("X_0",statValue)) + statRange}</div>
            }
        }
    }

    const greaterAffixStats = item.stats ? findAllByKeyValue(item.stats, "greaterAffix", true) : null;
    const greaterAffixSize = item.stats && greaterAffixStats ? greaterAffixStats.length : 0;
    const itemTitleDiv = item.title ?
        greaterAffixSize === 0 ?
            <div style={titleStyle}>{item.title}</div>
            : greaterAffixSize === 1 ? <div style={titleStyle}>{item.title} &#10039;</div>
                : greaterAffixSize === 2 ? <div style={titleStyle}>{item.title} &#10039; &#10039;</div>
                    : greaterAffixSize === 3 ? <div style={titleStyle}>{item.title} &#10039; &#10039; &#10039;</div> : null
        : null;


    const style = maxHeight ? {height:'100%'} : null;

    return (
        <div className="itemcard" style={style}>
            {
                item.title ? itemTitleDiv : null
            }
            {
                isCustomItem ?
                    <Flex direction={"column"} gap={"0rem"}>
                        <Label style={customItemNameStyle}>{itemName + amountText}</Label>
                        <Label style={grayStyle}>{itemSubType}</Label>
                        <Divider/>
                        {
                            customItemDescription.map((description, index) => <Label key={index} style={whiteStyle}>{description}</Label>)
                        }
                    </Flex>
                    : [
                        <div key={"amount"} style={item.title ? typeStyle : titleStyle}>{itemName + amountText}</div>,
                        <div key={"itemSubType"} style={typeStyle}>{itemSubType}</div>
                    ]
            }
            {
                item.itemPower > 0 ? <div style={normalTextStyle}>{item.itemPower + " Item Power"}</div> : null
            }
            {
                isArmor || isDps ?
                    <div>
                        <Divider/>
                        {
                            isArmor ? <Label style={whiteStyle}>{item.armor + " Armor"}</Label> : null
                        }
                        {
                            isDps ? <Label style={whiteStyle}>{item.dps + " Damage Per Second"}</Label> : null
                        }
                    </div> : null
            }
            {
                item.basicStats ? <div>
                    {
                        isArmor || isDps ? null : <Divider/>
                    }
                    {
                        item.basicStats.map((stat, index) => mapStat(stat, index))
                    }
                </div> : null
            }
            {
                isWithoutStats || !item.stats || item.stats.length === 0 ? null :
                    <div>
                        <Divider/>
                        {
                            item.stats.map(mapStat)
                        }
                    </div>
            }
            {
                isWithoutStats || !item.aspects || item.aspects.length === 0 ? null :
                    <div>
                        {
                            item.aspects.map((stat, index) => mapStat(stat, index, true))
                        }
                    </div>
            }
            {
                isWithoutStats || !item.uniqueAffixes || item.uniqueAffixes.length === 0 ? null :
                    <div>
                        {
                            item.uniqueAffixes.map((stat, index) => mapStat(stat, index, false, true))
                        }
                    </div>
            }
            {
                item.levelRequirement > 0 ? <Flex direction={"row"} justifyContent={"flex-end"}><Label style={whiteStyle}>{"Requires Level " + item.levelRequirement}</Label></Flex> : null
            }
            {
                item.classRequirement && item.classRequirement !== "Any" ? <Flex direction={"row"} justifyContent={"flex-end"}>
                    <div style={normalTextStyle}>{item.classRequirement} </div>
                </Flex> : null
            }
        </div>
    );
};
export default D4ItemCard;