import React from "react"
import {Flex, Menu, MenuButton, MenuItem, Tabs} from "@aws-amplify/ui-react";
import EiaCard from "../components/cards/EiaCard";
import AoZCalculator from "../components/calculators/AoZCalculator";
import NmCalculator from "../components/calculators/NmCalculator";
import D4Calendar from "./tools/D4Calendar";
import WorldBossCalendar from "./tools/WorldBossCalendar";
import Utils from "../commons/Utils/Utils";
import {useLocation, useNavigate} from "react-router-dom";
import {getFirstIndexOfKeyValueMatch} from "../commons/Utils/ArrayUtils";
import * as Navigation from "../commons/enums/Navigation";
import BossSummoningS5 from "./tools/BossSummoningS5";
import BossSummoning from "./tools/BossSummoning";
import PitMasterworking from "./tools/PitMasterworking";

const isSmallScreen = Utils.isSmallScreen();

const tabValues = [
    {value: Navigation.SUB_PATH_D4_CALENDAR, title: "Diablo 4 calendar"},
    {value: Navigation.SUB_PATH_D4_BOSS_MATERIALS, title: "Boss Summoning"},
    {value: Navigation.SUB_PATH_D4_ARCHIVE, title: "Archive"},
]

const archiveTabValues = [
    "World boss calendar (Season 5)",
    "Boss Summoning (Season 5)",
    "Pit Masterworking (Season 5)",
    "Nightmare Dungeons (Season 5)",
    "Abattoir of Zir (Season 2)"
]

const archiveTabContents = [
    <EiaCard>
        <WorldBossCalendar/>
    </EiaCard>,

    <EiaCard>
        <BossSummoningS5/>
    </EiaCard>,

    <PitMasterworking/>,

    <NmCalculator/>,

    <AoZCalculator/>
]

const tabContents = [
    <EiaCard>
        <D4Calendar/>
    </EiaCard>,

    <EiaCard>
        <BossSummoning/>
    </EiaCard>,

    <Tabs.Container defaultValue={archiveTabValues[2]} isLazy justifyContent="flex-start">
        <Tabs.List>
            {archiveTabValues.map((tabValue) => <Tabs.Item key={tabValue} value={tabValue}>{tabValue}</Tabs.Item>)}
        </Tabs.List>
        {
            archiveTabContents.map((tabContent, index) =>
                <Tabs.Panel key={index} value={archiveTabValues[index]}>
                    {tabContent}
                </Tabs.Panel>)
        }
    </Tabs.Container>,
]

const defaultContentIndex = 1;

const ToolsPage = function (props) {

    const navigateWithRouter = useNavigate();

    const navigate=function(value) {
        if(isSmallScreen) {
            Utils.scrollToStickyNavigationBar();
        }

        navigateWithRouter("/"+Navigation.TOOLS+"/" + value);
    }

    let location = useLocation();
    const {basePath, subPath} = Utils.getPaths(location.pathname);
    const contentIndexBySubPath = subPath ? getFirstIndexOfKeyValueMatch(tabValues, "value", subPath) : defaultContentIndex;
    //we show default content when we do not find the sub-path to prevent dead links
    const currentContentIndex = contentIndexBySubPath >= 0 ? contentIndexBySubPath : defaultContentIndex;

    return isSmallScreen ?

            <EiaCard>
                <Flex direction={"column"}>
                    <Menu
                        size={"large"}
                        menuAlign="start"
                        trigger={
                            <MenuButton variation="outlined" size="large">
                                Select Tool ...
                            </MenuButton>}
                    >
                        {
                            tabContents.map((tabContent, index) => {
                                return (<MenuItem key={index} onClick={() => navigate(tabValues[index].value)}>
                                    {tabValues[index].title}
                                </MenuItem>)
                            })
                        }
                    </Menu>
                    {tabContents[currentContentIndex]}
                </Flex>
            </EiaCard>

        :

        <Tabs.Container defaultValue={tabValues[currentContentIndex].value} isLazy justifyContent="flex-start" onValueChange={(value) => navigate(value)}>
            <Tabs.List>
                {tabValues.map((tabValue) => <Tabs.Item key={tabValue.value} value={tabValue.value}>{tabValue.title}</Tabs.Item>)}
            </Tabs.List>
            {
                tabContents.map((tabContent, index) =>
                    <Tabs.Panel key={index} value={tabValues[index].value}>
                        {tabContent}
                    </Tabs.Panel>)
            }
        </Tabs.Container>;
}
export default ToolsPage;