import {Flex, Heading, Tabs} from "@aws-amplify/ui-react";
import React from "react";
import EiaCard from "../../components/cards/EiaCard";
import EiaHelpButton from "../../components/EiaHelpButton";
import YoutubeVideo from "../../components/YoutubeVideo";
import PitCalculator_Neathiron from "../../components/calculators/PitCalculator_Neathiron";
import PitCalculator_Ingolith from "../../components/calculators/PitCalculator_Ingolith";
import PitCalculator_Obducite from "../../components/calculators/PitCalculator_Obducite";

const BossSummoning = function () {

    return <div>
        <EiaCard>
            <Flex direction={"row"}>
                <Heading level={4}>Calculate the fastest way to masterwork your items</Heading>
                <EiaHelpButton title={"Calculate the fastest way to masterwork your items"}>
                    <YoutubeVideo src={"https://www.youtube.com/embed/moGgDz0kJ34?si=y321fuXYihnX80U7"}/>
                </EiaHelpButton>
            </Flex>
        </EiaCard>
        <Tabs.Container isLazy defaultValue={"Neathiron"} justifyContent="flex-start">
            <Tabs.List>
                <Tabs.Item value="Neathiron">{"Neathiron"}</Tabs.Item>
                <Tabs.Item value="Ingolith">{"Ingolith"}</Tabs.Item>
                <Tabs.Item value="Obducite">{"Obducite"}</Tabs.Item>
            </Tabs.List>
            <Tabs.Panel value="Neathiron"><PitCalculator_Neathiron/></Tabs.Panel>
            <Tabs.Panel value="Ingolith"><PitCalculator_Ingolith/></Tabs.Panel>
            <Tabs.Panel value="Obducite"><PitCalculator_Obducite/></Tabs.Panel>
        </Tabs.Container>
    </div>
}

export default BossSummoning;