import {Badge, Flex, Heading, Image, Text, useTheme} from "@aws-amplify/ui-react";
import React from "react";
import welcomeImage from '../resources/welcomeImage.jpg'
import EiaCard from "../components/cards/EiaCard";
import Utils from "../commons/Utils/Utils";
import {BestPrices, EiaNavigationLink, Search, Sell, Tools} from "../components/EiaNavigationLink";
import * as Navigation from "../commons/enums/Navigation";

const isSmallScreen = Utils.isSmallScreen();
const direction = isSmallScreen ? "column" : "row";

const WelcomePage = function(props) {
    const {tokens} = useTheme();

    const eitBadges =         <Flex gap="0.5rem" wrap={"wrap"}>
        <Badge>Diablo 4</Badge><Badge>Market</Badge><Badge>Easy</Badge><Badge>Free</Badge><Badge>Only game items</Badge><Badge>Only game currencies</Badge><Badge>No real money</Badge>
    </Flex>;


    const featureBadges =         <Flex gap="0.5rem" wrap={"wrap"}>
        <Badge>Easy</Badge><Badge>Item Screenshot Detection</Badge><Badge>Price Check</Badge><Badge>Search & Filter</Badge><Badge>Search & Sort</Badge><Badge>Best Prices</Badge><Badge>Tools</Badge>
    </Flex>;

    const pitLink = <EiaNavigationLink key={"pit"} relativeLink={Navigation.TOOLS_D4_ARCHIVE} title={"Pit Masterworking"} />

    return(
        <Flex direction={direction}>
            <Flex
                direction="column"
                columnGap="1rem"
            >
                <EiaCard>
                    <Flex direction="column" columnGap="2rem">
                        <Heading level={3}>Get Started</Heading>
                        <Text fontWeight={tokens.fontWeights.bold} fontSize={tokens.fontSizes.large} textAlign="left">
                            {[Search, " to browse the market"]}
                        </Text>
                        <Text fontWeight={tokens.fontWeights.bold} fontSize={tokens.fontSizes.large} textAlign="left">
                            {[BestPrices, " to check the best prices for e.g. runes or summoning materials"]}
                            {/*{[BestPrices, " to check the best prices for e.g. ", stygianStone(), " or ", exquisiteBlood(), " or ", mucusSlickEgg(), " etc."]}*/}
                        </Text>
                        <Text fontWeight={tokens.fontWeights.bold} fontSize={tokens.fontSizes.large} textAlign="left">
                            {[Sell, " to detect / price check / sell your items via screenshot"]}
                        </Text>
                    </Flex>
                </EiaCard>
                <EiaCard>
                    <Flex direction="column" columnGap="2rem">
                        <Heading level={3}>What is Easy Item Trading?</Heading>
                        {eitBadges}
                        <Text fontWeight={tokens.fontWeights.bold} fontSize={tokens.fontSizes.large} textAlign="left">
                            The goal is to trade easy and fast ingame items against ingame items or ingame currencies (no real money!). Currently we only support the game Diablo 4.
                            You can register now for free only with e-mail / nickname and start trading!
                        </Text>
                    </Flex>
                </EiaCard>
                <EiaCard>
                    <Flex direction="column" columnGap="2rem">
                        <Heading level={3} >What are the features?</Heading>
                        {featureBadges}
                        <Text fontWeight={tokens.fontWeights.bold} fontSize={tokens.fontSizes.large} textAlign="left">
                            {
                                [
                                    "You can easy screenshot your item and upload the screenshot on the ", Sell,
                                    " page to create your listing. EasyItemTrading has a text detection feature to find the correct stats."
                                ]
                            }
                        </Text>
                        <Text fontWeight={tokens.fontWeights.bold} fontSize={tokens.fontSizes.large} textAlign="left">
                            {["You can do an automated price check before you ", Sell, " your item to get an idea of the real price."
                            ]}
                        </Text>
                        <Text fontWeight={tokens.fontWeights.bold} fontSize={tokens.fontSizes.large} textAlign="left">
                            {
                                [
                                    "You can filter the ", Search,
                                    " for any implicit stat, affix, greater affix, aspect or unique affix."
                                ]
                            }

                        </Text>
                        <Text fontWeight={tokens.fontWeights.bold} fontSize={tokens.fontSizes.large} textAlign="left">
                            {[
                                "Easy Item Trading supports multiple currencies like e.g. Gold or Summoning materials what makes the offers sortable. It is easy to find the cheapest offer - checkout ",
                                Search, " and use table view to sort)."
                            ]}
                        </Text>
                        <Text fontWeight={tokens.fontWeights.bold} fontSize={tokens.fontSizes.large} textAlign="left">
                            {[
                                "A page to show the ", BestPrices,
                                " for each item type. This is the best way to get a quick overview of e.g. the current prices for diablo 4 summoning materials."
                            ]}
                        </Text>
                        <Text fontWeight={tokens.fontWeights.bold} fontSize={tokens.fontSizes.large} textAlign="left">
                            {[
                                "Useful ", Tools, " and calculators like e.g. ", pitLink,
                                " to calculate the fastest way to push your masterwork level on items."
                            ]}
                        </Text>
                    </Flex>
                </EiaCard>
            </Flex>
            {
                isSmallScreen ? <Flex direction={"row"} justifyContent={"center"}><Image src={welcomeImage} width={"70%"}/></Flex>
                    : <Image objectFit={"scale-down"} src={welcomeImage} width={"50%"}/>
            }
        </Flex>

    )
}
export default WelcomePage;